import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getError } from "../../../getError";
import { Store } from "../../../Store";

const UserDetailsPage = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Yükleniyor durumu
  const { state } = useContext(Store);
  const { lawyerInfo } = state;

  useEffect(() => {
    if (lawyerInfo?.token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URI}/api/users/${id}`, {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        })
        .then((response) => {
          setUser(response.data);
          setLoading(false); // Veriler yüklendiğinde yükleme biter
        })
        .catch((error) => {
          toast.error(getError(error));
          setLoading(false); // Hata olsa da yükleme bitmeli
        });
    } else {
      toast.error("Kullanıcı doğrulaması başarısız.");
      setLoading(false);
    }
  }, [id, lawyerInfo]);

  if (loading) {
    return <p>Yükleniyor...</p>; // Yüklenme sürecinde gösterilecek mesaj
  }

  if (!user) {
    return <p>Kullanıcı bilgisi bulunamadı.</p>; // Eğer veri yoksa
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "90%",
        margin: "10px",
        padding: "30px",
        backgroundColor: "#f7f7f7",
        borderRadius: "12px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        overflowY: "auto",
        zIndex: 1000,
      }}
    >
      <h3
        style={{
          marginBottom: "20px",
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        Kullanıcı Detayları
      </h3>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
          alignItems: "start",
        }}
      >
        <div>
          <h4 style={{ fontSize: "18px", marginBottom: "10px" }}>Bilgiler</h4>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
            }}
          >
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Adı:</td>
                <td style={{ padding: "8px" }}>{user?.name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Soyadı:</td>
                <td style={{ padding: "8px" }}>{user?.surname}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Email:</td>
                <td style={{ padding: "8px" }}>{user?.email}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>
                  Kimlik No:
                </td>
                <td style={{ padding: "8px" }}>{user?.identificationNumber}</td>
              </tr>

              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Telefon:</td>
                <td style={{ padding: "8px" }}>
                  {user?.phone?.countryCode
                    ? `${user?.phone.countryCode} ${user?.phone.phoneNumber}`
                    : "Bilgi yok"}
                </td>
              </tr>

              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Diller:</td>
                <td style={{ padding: "8px" }}>
                  {user?.languages?.join(", ") || "Bilgi yok"}
                </td>
              </tr>
              {/* <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>
                  Lokasyon:
                </td>
                <td style={{ padding: "8px" }}>
                  {user?.locationId.country || "Bilgi yok"}
                </td>
              </tr> */}
              <tr>
                <td
                  key={user?.request?._id}
                  style={{ fontWeight: "bold", padding: "8px" }}
                >
                  Randevular:
                </td>
                <td style={{ padding: "8px" }}>
                  {user?.request?._id || "Bilgi yok"},
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h4 style={{ fontSize: "18px", marginBottom: "10px" }}>
            Ek Detaylar
          </h4>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
            }}
          >
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>
                  Açıklama:
                </td>
                <td style={{ padding: "8px" }}>
                  {user?.description || "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Adres:</td>
                <td style={{ padding: "8px" }}>
                  {user?.address?.description || "Bilgi yok"},{" "}
                  {user?.address?.city || "Bilgi yok"},{" "}
                  {user?.address?.town || "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>
                  Hakkında:
                </td>
                <td style={{ padding: "8px" }}>{user?.about || "Bilgi yok"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsPage;
