import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { getError } from "../../getError";
import { toast } from "react-toastify";
import { FcWebcam } from "react-icons/fc";
import { Link } from "react-router-dom";

const LawyerDates = () => {
  const [lawyerRequest, setLawyerRequest] = useState([]);
  const { state } = useContext(Store);
  const { lawyerInfo } = state;
  const selectedRow = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const now = new Date().getTime();

  const getDisplayedDates = () => {
    const startIndex = (currentPage - 1) * 15;
    const endIndex = startIndex + 15;
    return lawyerRequest.slice(startIndex, endIndex);
  };

  const getLaywerRequest = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/requests/lawyer`, {
        headers: {
          Authorization: `Bearer ${lawyerInfo.token}`,
        },
      })
      .then((response) => {
        setLawyerRequest(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  };

  const handleFileUpload = async (requestId, file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/requests/lawyer-file/${requestId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${lawyerInfo.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Dosya başarıyla yüklendi.");
    } catch (error) {
      toast.error(getError(error));
    }
  };

  useEffect(() => {
    getLaywerRequest();
  }, []);

  const isMeetingLinkVisible = (meeting) => {
    let startedAtDate = new Date(meeting.startedAtDate).getTime();
    let endedAtDate = new Date(meeting.endedAtDate).getTime();
    console.log(meeting.startedAtDate, meeting.endedAtDate, new Date(now));

    if (now > startedAtDate && now < endedAtDate) {
      return (
        <Link to={`${meeting.meetingUrl}`} target="_blank">
          <FcWebcam style={{ width: "32px", height: "32px" }} />
        </Link>
      );
    } else if (now < startedAtDate) {
      return "Toplantı Saati gelmedi";
    } else {
      return "Toplantı Süresi Geçti";
    }
  };

  return (
    <div style={{ padding: "20px", color: " #00242E" }}>
      <p
        style={{
          font: "normal normal bold 20px/24px SF Pro Text",
        }}
      >
        Randevularım
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "640px",
        }}
      >
        <div>
          <table style={{ width: "100%", marginTop: "25px" }}>
            <thead>
              <tr
                style={{
                  font: " normal normal bold 16px/19px SF Pro Text",
                  height: "25px",
                }}
              >
                <th
                  style={{
                    border: "1px solid #2d3657",
                    width: "148px",
                    height: "33px",
                    opacity: "0.75",
                    paddingLeft: "7px",
                  }}
                >
                  Danışan Adı
                </th>
                <th
                  style={{
                    border: "1px solid #2d3657",
                    width: "145px",
                    opacity: "0.75",
                    paddingLeft: "7px",
                  }}
                >
                  Konu{" "}
                </th>
                <th
                  style={{
                    border: "1px solid #2d3657",
                    width: "103px",
                    opacity: "0.75",
                    paddingLeft: "7px",
                  }}
                >
                  Branş
                </th>
                <th
                  style={{
                    border: "1px solid #2d3657",
                    width: "86px",
                    opacity: "0.75",
                    paddingLeft: "7px",
                  }}
                >
                  Tarih
                </th>
                <th
                  style={{
                    border: "1px solid #2d3657",
                    width: "131px",
                    opacity: "0.75",
                    paddingLeft: "7px",
                  }}
                >
                  Durum
                </th>
                <th
                  style={{
                    border: "1px solid #2d3657",
                    width: "131px",
                    opacity: "0.75",
                    paddingLeft: "7px",
                  }}
                >
                  Görüşme
                </th>
              </tr>
            </thead>
            <tbody
              style={{ font: "normal small normal 14px/16px SF Pro Text" }}
            >
              {lawyerRequest?.length > 0 &&
                getDisplayedDates().map((request, index) => (
                  <tr
                    key={request._id}
                    style={{
                      borderBottom: "1px solid #2d3657",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      font: "normal normal normal 14px/16px SF Pro Text",
                      backgroundColor:
                        selectedRow === index ? "#e9ddbf" : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <td
                      style={{
                        paddingTop: "7px",
                        paddingBottom: "7px",
                        paddingLeft: "5px",
                      }}
                    >
                      {request.userId?.name + " " + request.userId?.surname}{" "}
                    </td>
                    <td
                      style={{
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                    >
                      {request.description.toString().substring(0, 20) + " ..."}
                    </td>
                    <td
                      style={{
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                    >
                      {request?.branchId.title}
                    </td>
                    <td style={{ paddingTop: "7px", paddingBottom: "7px" }}>
                      {request?.selectedDate ? (
                        new Date(request?.selectedDate).toLocaleDateString(
                          "tr-TR",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )
                      ) : (
                        <>
                          {new Date(request?.startDate).toLocaleDateString(
                            "tr-TR",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}{" "}
                          -
                          {new Date(request?.endDate).toLocaleDateString(
                            "tr-TR",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </>
                      )}
                    </td>
                    <td
                      style={{
                        padding: "2px",
                        margin: "5px 0",
                        backgroundColor: getStatusColor(request?.status),
                        color: getStatusTextColor(request?.status),
                        borderRadius: "25px",
                        textAlign: "center",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        width: "180px",
                        height: "33px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      {getStatusText(request?.status)}
                      {request?.status === "accepted" && (
                        <div style={{ marginLeft: "10px" }}>
                          <input
                            type="file"
                            id={`file-upload-${request._id}`}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleFileUpload(request._id, e.target.files[0])
                            }
                          />
                          <label
                            htmlFor={`file-upload-${request._id}`}
                            style={{
                              background: "#2d3657",
                              color: "#ffffff",
                              padding: "4px 8px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              fontSize: "12px",
                              margin: "2px 1px",
                            }}
                          >
                            Dosya Ekle
                          </label>
                        </div>
                      )}
                    </td>
                    <td>
                      {request.status === "accepted" &&
                      request?.meetingId?.meetingUrl
                        ? isMeetingLinkVisible(request.meetingId)
                        : "------------"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          {Array(Math.ceil(lawyerRequest.length / 15))
            .fill(0)
            .map((_, i) => (
              <button
                key={i}
                style={{
                  border: currentPage === i + 1 ? "none" : "1px solid #00242E",
                  background: currentPage === i + 1 ? "#2d3657" : "",
                  borderRadius: "14px",
                  font: "normal normal normal 16px SF Pro Text",
                  padding: "2px 6px",
                  color: currentPage === i + 1 ? "#FFFFFF" : "#00242e",
                  marginRight: "5px",
                }}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};
export default LawyerDates;

// Utility functions for status color and text color
const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#F8C106";
    case "accepted":
      return "#10986F";
    case "canceled":
      return "#F64E60";
    case "completed":
      return "#3699FF";
    default:
      return "transparent";
  }
};

const getStatusTextColor = (status) => {
  return "#FFFFFF"; // White color for all statuses
};

const getStatusText = (status) => {
  switch (status) {
    case "pending":
      return "Bekliyor";
    case "accepted":
      return "Kabul Edildi";
    case "canceled":
      return "İptal Edildi";
    case "completed":
      return "Tamamlandı";
    default:
      return "";
  }
};
