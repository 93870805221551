import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { getError } from "../../getError";
import "./admin.css";
import FilterLawyers from "./components/FilterLawyers";
import Appointments from "./components/Appoinments";

const RequestUpdate = ({ request, setSelectedRow, lawyerInfo }) => {
  const [selectDate, setSelectDate] = useState(
    request?.selectedDate ? new Date(request?.selectedDate) : new Date()
  );
  const [selectedLawyerId, setSelectedLawyerId] = useState(
    request?.lawyerId ? request?.lawyerId._id : null
  );
  const [lawyerRequests, setLawyerRequests] = useState(null);
  const [status, setStatus] = useState(request?.status);
  const [filteredData, setFilteredData] = useState({
    lawyers: [],
    countLawyers: 0,
    page: 1,
    pages: 0,
  });

  useEffect(() => {
    if (request.branchId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URI}/api/lawyers/search?branchId=${request?.branchId._id}`
        )
        .then((response) => {
          setFilteredData(response.data);
        })
        .catch((error) => {
          toast.error(getError(error));
        });
    }
  }, [request?.branchId]);

  useEffect(() => {
    if (selectedLawyerId) {
      fetchLawyerRequest();
    }
  }, [selectedLawyerId]);

  const fetchLawyerRequest = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/api/requests/search?lawyerId=${selectedLawyerId}`,
        {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        }
      );
      if (response.data) {
        setLawyerRequests(response.data);
      }
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const handlePrevious = () => {
    if (filteredData.page > 1) {
      setFilteredData((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (filteredData.page < filteredData.totalPages) {
      setFilteredData((prev) => prev + 1);
    }
  };
  
  const handleUpdateRequest = async () => {
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/api/requests/${request._id}`,
        {
          lawyerId: selectedLawyerId,
          selectedDate: selectDate,
          selectTime: selectDate.getHours() + ":" + selectDate.getMinutes(),
          status: status,
        },
        {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        }
      );
      if (resp.data) {
        toast.success("Guncelleme Tamamlandı");
        if (resp.data.status === "accepted" || resp.data.status === "updated" ) {
          handleCreateMeeting();
        }
      }
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const handleCreateMeeting = async () => {
    try {
      if (
        request?.status !== "accepted" ||
        !request?.selectedDate ||
        !request?.lawyerId
      ) {
        toast.error("Randevu talebini eksiksiz doldurun.");
        return; // Fonksiyonu burada durdur
      }

      if (!request?.meetingId) {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/api/meetings`,
          {
            requestId: request._id,
            name: `Meeting ${request?.userId.name} ${request?.userId.surname} with Av.${request?.lawyerId.name}`,
            isAvailable: true,
          },
          {
            headers: { Authorization: `Bearer ${lawyerInfo.token}` },
          }
        );

        if (resp.status === 201) {
          toast.success("Toplantı Linki Oluşturuluyor...");
          await startMeeting(resp.data);
        }
      } else if (request?.meetingId) {
        const resp = await axios.put(
          `${process.env.REACT_APP_BASE_URI}/api/meetings/${request?.meetingId._id}`,
          {
            requestId: request._id,
            isAvailable: true,
          },
          {
            headers: { Authorization: `Bearer ${lawyerInfo.token}` },
          }
        );

        if (resp.status === 200) {
          toast.success("Toplantı Linki Güncelleniyor...");
          await startMeeting(resp.data);
        }
      }
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const startMeeting = async (meeting) => {
    try {
      if (!meeting?.requestId || !meeting?._id) {
        toast.error("Toplantı Linki oluşturulmadı. ");
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_BASE_URI}/api/meetings/${meeting?._id}/start`,
        {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        }
      );

      if (response.status === 200) {
        toast.success("Toplantı Linki Oluşturuldu");
      }
    } catch (error) {
      toast.error(getError(error));
    }
  };

  return (
    <Modal
      show={request !== null}
      onHide={() => {
        setSelectedRow(false);
        setSelectDate(null);
      }}
      animation={false}
    >
      <div
        style={{
          height: "750px",
          minWidth: "400px",
          maxWidth: "100%",
          padding: "20px",
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <h1>Randevu Güncelleme</h1>
        <hr />

        <div className="d-flex justify-content-between align-items-center mt-2">
          <div>
            <h4 className="mb-2 fs-3">Gorusme tarihi</h4>
            <DatePicker
              placeholderText="Gorusme için uygun tarihi seçiniz"
              dateFormat="MMMM d, yyyy h:mm aa"
              selected={selectDate}
              startDate={request.startDate}
              endDate={request.endDate}
              showTimeSelect
              onChange={(date) => setSelectDate(date)}
              minDate={new Date()}
              className="form-control mb-2 w-100 px-5"
            />
          </div>

          <div>
            <h4 className="fs-3 mb-2">Gorusme Durumu</h4>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              style={{
                height: "100%",
                border: "#fff .5px solid",
                borderRadius: "7px",
                padding: "7px",
                cursor: "pointer",
                backgroundColor: "#fff",
              }}
              className="w-100"
            >
              <option value="pending">Beklemede</option>
              <option value="accepted">Kabul Edildi</option>
              <option value="canceled">Red Edildi</option>
              <option value="completed">Tamamlandı</option>
            </select>
          </div>
        </div>

        <h4 className="mt-4 mb-2 fs-2">Sectiginiz Avukat</h4>
        {filteredData?.lawyers ? (
          <FilterLawyers
            filteredData={filteredData}
            selectedLawyerId={selectedLawyerId}
            setSelectedLawyerId={setSelectedLawyerId}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        ) : (
          "lawyers with this branch not found"
        )}

        <div className="">
            {lawyerRequests && <Appointments requests={lawyerRequests.requests} />}
        </div>
      </div>

      <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
        <button
          className="btn btn-secondary fs-4"
          onClick={() => setSelectedRow(false)}
        >
          İptal
        </button>
        <button
          className="btn btn-secondary fs-4 ms-2"
          onClick={() => handleUpdateRequest()}
        >
          Avukat Ata
        </button>
      </div>
    </Modal>
  );
};

export default RequestUpdate;
