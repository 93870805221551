import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../getError";

const AllUsers = ({ handleDateClick, index }) => {
  const [users, setUsers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { state } = useContext(Store);
  const { lawyerInfo } = state;

  useEffect(() => {
    if (lawyerInfo?.token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URI}/api/users`, {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        })
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          toast.error(getError(error));
        });
    } else {
      toast.error("Kullanıcı doğrulaması başarısız.");
    }
  }, [lawyerInfo]);

  const getDisplayedDates = () => {
    const startIndex = (currentPage - 1) * 15;
    const endIndex = startIndex + 15;
    return users.slice(startIndex, endIndex);
  };

  const handleDetailClick = (userId) => {
    window.open(`/user-detail/${userId}`, "_blank"); // Yeni sekmede aç
  };

  return (
    <div style={{ padding: "10px", color: " #00242E" }}>
      <p
        style={{
          font: "normal normal bold 20px/24px SF Pro Text",
        }}
      >
        Kayıtlı Kullanıcılar
      </p>
      <table style={{ width: "100%", marginTop: "25px" }}>
        <thead>
          <tr
            style={{
              font: "normal normal bold 16px/19px SF Pro Text",
              height: "25px",
            }}
          >
            <th
              style={{
                border: "1px solid #2d3657",
                width: "148px",
                height: "33px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Danışan
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "145px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Telefon Numarası
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Yer
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "100px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Detay
            </th>
          </tr>
        </thead>
        <tbody style={{ font: "normal small normal 14px/16px SF Pro Text" }}>
          {getDisplayedDates().map((user, index) => (
            <tr
              key={user._id}
              onClick={() => handleDateClick(user, index)}
              style={{
                borderBottom: "1px solid #2d3657",
                paddingTop: "10px",
                paddingBottom: "10px",
                font: "normal normal normal 14px/16px SF Pro Text",
                backgroundColor:
                  selectedRow === index ? "#2d3657" : "transparent",
              }}
            >
              <td style={{ paddingTop: "7px", paddingBottom: "7px" }}>
                {user?.name}-{user?.surname}
              </td>
              <td style={{ paddingTop: "7px", paddingBottom: "7px" }}>
                {user?.phone?.countryCode}-{user?.phone?.phoneNumber}
              </td>
              <td style={{ paddingTop: "7px", paddingBottom: "7px" }}>
                <p>{user?.location?.country}</p>
                <p>{user?.address?.city}</p>
              </td>
              <td style={{ paddingTop: "7px", paddingBottom: "7px" }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Satır tıklamasını önle
                    handleDetailClick(user._id); // Yeni sekmede aç
                  }}
                  style={{
                    backgroundColor: "#2d3657",
                    color: "#fff",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Detay
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}
      >
        {Array(Math.ceil(users.length / 15))
          .fill(0)
          .map((_, i) => (
            <button
              key={i}
              style={{
                border: currentPage === i + 1 ? "none" : "1px solid #00242E",
                background: currentPage === i + 1 ? "#2d3657" : "",
                borderRadius: "14px",
                font: "normal normal normal 16px SF Pro Text",
                padding: "2px 6px",
                color: currentPage === i + 1 ? "#FFFFFF" : "#00242e",
                marginRight: "5px",
              }}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
      </div>
    </div>
  );
};

export default AllUsers;
